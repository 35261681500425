@import 'normalise';

/*------------------------------ ROOT STUFF ------------------------------*/

:root {
  --baseline-unit: 4px;
  /*--base-font-size-s: 16px;*/
  --base-font-size: 19px;
  --small-font-size: 16px;
  --base-weight: 400;
  --base-bold-weight: 600;
  --base-heading-weight: 400;

  --body-colour: #222833;

  --paynes-grey: #40404F;
  /* dark to light tints */
  --paynes-grey-tint-1: #B3B3B9;
  --paynes-grey-tint-2: #C5C5CA;
  --paynes-grey-tint-3: #D9D9DC;
  --paynes-grey-tint-4: #EBEBED;
  --paynes-grey-tint-5: #FBFBFC;

  --paynes-grey-shadow: 0 2px 4px 2px rgba(64, 64, 79, 0.1);

  --white: #ffffff;
  --warm-yellow: #FFC800;

  --grey: #CBC3BC;
  --grey-15: #F5F3F2;
  --mustard: #F6C769;
  --ochre: #F6AE00;
  --sienna: #6D3900;
  --umber: #6D574F;
}

html {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
  font-size: var(--base-font-size);
  font-weight: var(--base-weight);
  line-height: calc(var(--baseline-unit) * 7);
}

body {
  color: var(--body-colour);
  background-color: var(--white);
}

::selection {
  color: var(--body-colour);
  background-color: var(--warm-yellow);
}

/*----------------------------- STRONG ETC -----------------------------*/

strong,
b {
  font-weight: var(--base-bold-weight);
}

/*----------------------------- LINKS -----------------------------*/

a {
 // position: relative;
  color: var(--body-colour);
  text-decoration: none;
  box-shadow: 0 2px var(--sienna);
  transition: all 0.1s ease-out;
}
a:focus {
  background-color: var(--white);
  outline: calc(var(--baseline-unit)/2) solid var(--sienna);
}
a:hover,
a:focus,
a:active {
  box-shadow: 0 4px var(--sienna);
}

a.simple:hover,
a.simple:focus,
a.simple:active {
  color: var(--body-colour);
}

a.alt {
  color: var(--white);
  background-color: transparent;
  box-shadow: 0 2px var(--white);
}
a.alt:hover,
a.alt:focus,
a.alt:active {
  color: var(--body-colour);
  background-color: var(--warm-yellow);
  box-shadow: 0 2px var(--warm-yellow);
}

.back::before {
  content: ' ';
  display: inline-block;
  width: 0;
  height: 0;
  margin: 0 var(--baseline-unit) var(--baseline-unit) 0;
  border-top: var(--baseline-unit) solid transparent;
  border-bottom: var(--baseline-unit) solid transparent;
  border-right: var(--baseline-unit) solid var(--warm-yellow);
}

/*----------------------------- INLINE -----------------------------*/

abbr[title] {
  text-decoration: underline wavy var(--paynes-grey);
}

a abbr[title] {
  text-decoration: none;
}

/*----------------------------- IMAGES -----------------------------*/

img {
  display: block;
  max-width: 100%;
}

figure {
  margin: calc(var(--baseline-unit) * 5) 0;
}
@media screen and (min-width: 468px) {
  figure {
    margin: calc(var(--baseline-unit) * 7) 0;
  }
}
figcaption {
  font-size: var(--small-font-size);
  padding: calc(var(--baseline-unit) * 2);
  padding-left: 0;
}

/*----------------------------- GENERAL HEADERS -----------------------------*/

/*

--small = Minor 3rd - 1.200
--med = Perfect 4th - 1.333

*/

h1, h2, h3, h4, h5, h6,
.heading-xxl, .heading-xl, .heading-l, .heading-m {
  font-family: 'IBM Plex Serif', Georgia, serif;
  font-size: inherit;
  font-weight: var(--base-heading-weight);
  color: var(--body-colour);
}

h1,
.heading-xxl {
  font-size: 2.074em; /* skip one? it's 2.488 */
  line-height: calc(var(--baseline-unit) * 11);
  margin: calc(var(--baseline-unit) * 8) 0;
}
@media screen and (min-width: 468px) {
  h1,
  .heading-xxl {
    font-size: 3.157em; /* skip one? it's 4.209 */
    line-height: calc(var(--baseline-unit) * 16);
    margin: calc(var(--baseline-unit) * 12) 0;
  }
}

h2,
.heading-xl {
  font-size: 1.728em;
  line-height: calc(var(--baseline-unit) * 10);
  margin: calc(var(--baseline-unit) * 8) 0 calc(var(--baseline-unit) * 6) 0;
}
@media screen and (min-width: 468px) {
  h2,
  .heading-xl {
    font-size: 2.369em;
    line-height: calc(var(--baseline-unit) * 12);
    margin: calc(var(--baseline-unit) * 12) 0 calc(var(--baseline-unit) * 10) 0;
  }
}

h3,
.heading-l {
  font-size: 1.44em;
  line-height: calc(var(--baseline-unit) * 9);
  margin: calc(var(--baseline-unit) * 8) 0 calc(var(--baseline-unit) * 6) 0;
}
@media screen and (min-width: 468px)  {
  h3,
  .heading-l {
    font-size: 1.777em;
    line-height: calc(var(--baseline-unit) * 10);
    margin: calc(var(--baseline-unit) * 8) 0 calc(var(--baseline-unit) * 6) 0;
  }
}

h4,
.heading-m {
  font-size: 1.2em;
  line-height: calc(var(--baseline-unit) * 8);
  margin: calc(var(--baseline-unit) * 6) 0 calc(var(--baseline-unit) * 4) 0;
}
@media screen and (min-width: 468px) {
  h4,
  .heading-m {
    font-size: 1.333em;
    line-height: calc(var(--baseline-unit) * 8);
    margin: calc(var(--baseline-unit) * 6) 0 calc(var(--baseline-unit) * 4) 0;
  }
}

h5, h6, .heading-s {
  font-size: var(--base-font-size);
  font-weight: var(--base-heading-weight);
}


.combo-title p {
  margin: calc(var(--baseline-unit) * 3) 0 calc(var(--baseline-unit) * 7) 0;
}
.combo-title h1:first-child,
.combo-title h2:first-child,
.combo-title h3:first-child {
  margin-bottom: 0;
}
.combo-title p:first-child {
  margin: calc(var(--baseline-unit) * 10) 0 0 0;
}
.combo-title p:first-child + h1,
.combo-title p:first-child + h2,
.combo-title p:first-child + h3 {
  margin-top: 0;
}

.header-disguise {
  margin: 0;
  display: inline;
}

/*----------------------------- PARAGRAPHS -----------------------------*/

p {
  margin: calc(var(--baseline-unit) * 5) 0;
}
@media screen and (min-width: 468px) {
  p {
    margin: calc(var(--baseline-unit) * 7) 0;
  }
}

/*----------------------------- LISTS -----------------------------*/

ol,
ul {
  margin: calc(var(--baseline-unit) * 5) 0;
}
@media screen and (min-width: 468px) {
  ol,
  ul {
    margin: calc(var(--baseline-unit) * 7) 0;
  }
}

ol {
  padding-left: 0;
  list-style: none;
  counter-reset: ol-counter;
}
ul {
  padding-left: 1em;
}

li {
  margin-bottom: calc(var(--baseline-unit) * 2);
}
ol li {
  counter-increment: ol-counter;
  position: relative;
  padding-left: 2em;
}
ol li::before {
  position: absolute;
  left: 0;
  content: counter(ol-counter) ". ";
  display: inline-block;
}

/*----------------------------- RULES -----------------------------*/

hr {
  position: relative;
  border: 0;
  height: 1px;
  margin: calc(var(--baseline-unit) * 12) 0;
}
hr:after {
  content: ' ';
  position: absolute;
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed var(--umber);
}

/*----------------------------- CODE and PRE -----------------------------*/

pre {
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.code-block {
  padding: calc(var(--baseline-unit) / 2) calc(var(--baseline-unit) * 7);
  background-color: var(--paynes-grey);
  box-shadow: var(--paynes-grey-shadow);
}

code {
  font-family: monospace;
  font-size: var(--small-font-size);
  color: var(--white);
  background-color: var(--paynes-grey);
}

/*----------------------------- DETAILS -----------------------------*/

summary {
  cursor: pointer;
  position: relative;
  list-style: none;
  padding-left: calc(var(--baseline-unit) * 6);
  text-decoration: underline dotted var(--paynes-grey);
  &:before {
    position: absolute;
    display: block;
    left: 0;
    top: calc(var(--baseline-unit) * 2);
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    border-left-color: var(--paynes-grey);
    border-width: 7px 0 7px 12px;
    content: '';
  }
}

details[open] summary:before {
  border-width: 12px 7px 0 7px;
  border-left-color: transparent;
  border-top-color: var(--paynes-grey);
}

summary + p {
  margin: calc(var(--baseline-unit) * 2) 0;
}

/*----------------------------- BLOCKQUOTE -----------------------------*/

blockquote {
  position: relative;
  margin: calc(var(--baseline-unit) * 10) 0;
  padding: 0 0 0 calc(var(--baseline-unit) * 8);
  border-left: var(--baseline-unit) solid var(--warm-yellow);
}
@media screen and (min-width: 468px) {
  blockquote {
    margin: calc(var(--baseline-unit) * 14) 0;
  }
}

/*----------------------------- IFRAME -----------------------------*/

.embed-16-9 {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  background-color: var(--paynes-grey);
}
.embed-16-9 .object {
  position: absolute;
  width: 100%;
  height: 100%;
}

/*----------------------------- GLOBAL CHUNKS -----------------------------*/

/*----------------------------- HEADER AND FOOTER ----------------------------*/

.site-header {
  background-color: var(--grey-15);
  padding: calc(var(--baseline-unit) * 4) calc(var(--baseline-unit) * 4) 0 calc(var(--baseline-unit) * 4);
}
.site-footer {
  border-top: 1px dashed var(--umber);
  background-color: var(--grey-15);
  padding: calc(var(--baseline-unit) * 4);
  padding-bottom: calc(var(--baseline-unit) * 6);
}
@media screen and (min-width: 468px) {
  .site-header {
    padding-top: calc(var(--baseline-unit) * 6);
  }
  .site-footer {
    padding-top: calc(var(--baseline-unit) * 6);
    padding-bottom: calc(var(--baseline-unit) * 8);
  }
  .site-header > div,
  .site-footer > div {
    margin: 0 auto;
    width: 100%;
    max-width: 960px;
  }
}

/*----------------------------- PAGE TITLE AREA -----------------------------*/

.title-area {
  //color: var(--white);
  //background-color: var(--body-colour);
  border-bottom: 1px dashed var(--umber);
}
.title-area h1 {
  //color: var(--white);
}

/*----------------------------- HOMEPAGE SPLASH -----------------------------*/

@media screen and (min-width: 700px) {
  .splash {
    display: grid;
    grid-template-columns: 180px 1fr;
    grid-column-gap: calc(var(--baseline-unit) * 8);
    align-items: center;
  }
}

/*----------------------------- CONTENT BLOCKS -----------------------------*/

.content {
  padding: calc(var(--baseline-unit) * 4);
  padding-bottom: calc(var(--baseline-unit) * 10);
}
@media screen and (min-width: 468px) {
  .content {
    padding-top: calc(var(--baseline-unit) * 6);
    padding-bottom: calc(var(--baseline-unit) * 14);
  }
  .content > * {
    margin: 0 auto;
    width: 100%;
    max-width: 960px;
  }
}

.measure {
  max-width: 34em;
}

.full-width {
  box-sizing: border-box;
  width: calc(100vw - (var(--baseline-unit) * 8));
}
@media screen and (min-width: 990px) {
  .full-width {
    width: 960px;
  }
}

.full-width > img {
  display: block;
  margin: calc(var(--baseline-unit) * 5) 0;
}
@media screen and (min-width: 990px) {
  .full-width > img {
    margin: calc(var(--baseline-unit) * 7) 0;
  }
}

.phone-image {
  max-width: 360px;
}

.cols-2 > * {
  margin-bottom: calc(var(--baseline-unit) * 8);
}
.cols-2:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 990px) {
  .cols-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: calc(var(--baseline-unit) * 8);
  }
  .cols-2 > * {
    margin-bottom: 0;
  }
}

/*----------------------------- GLOBAL NAV -----------------------------*/

.site-nav {
  padding-bottom: calc(var(--baseline-unit) * 4);
  padding-top: calc(var(--baseline-unit) * 2);
  border-bottom: 1px dashed var(--umber);
  background-color: var(--grey-15);
  font-size: var(--small-font-size);
}
@media screen and (min-width: 468px) {
  .site-nav {
    padding-bottom: calc(var(--baseline-unit) * 8);
  }
}
@media screen and (min-width: 480px) {
  .site-nav {
    font-size: var(--base-font-size);
  }
}
.site-nav ul,
.site-nav li {
  margin: 0;
  padding: 0;
}
.site-nav li {
  display: inline-block;
  margin-right: calc(var(--baseline-unit) * 6);
}

/*----------------------------- BREADCRUMBS -----------------------------*/

.breadcrumbs {
  padding-bottom: calc(var(--baseline-unit) * 4);
  //background-color: var(--paynes-grey-tint-4);
  //border-bottom: 1px dashed var(--umber);
}
@media screen and (min-width: 468px) {
  .breadcrumbs {
    padding-bottom: calc(var(--baseline-unit) * 6);
  }
}
.breadcrumbs ol,
.breadcrumbs li {
  margin: 0;
  padding: 0;
}
.breadcrumbs li::before {
  position: static;
  content: " / ";
}
.breadcrumbs li:first-child::before {
  position: absolute;
  content: "";
}
.breadcrumbs li {
  display: inline-block;
}


/*----------------------------- PUFF STYLE CIRCULAR IMAGES -----------------------------*/

.roundel {
  width: 180px;
  height: 180px;
  clip-path: circle(50% at center);
  background-position: center;
  background-size: contain;
  margin: 0 auto;
}

@media screen and (min-width: 700px) {
  .roundel {
    margin: 0;
  }
}

/*----------------------------- CATEGORY DISPLAY -----------------------------*/

a.category-link {
  display: block;
  clear: both;
  background-color: transparent;
  margin-bottom: calc(var(--baseline-unit) * 7);
  box-shadow: none;
}
a.category-link:hover,
a.category-link:focus,
a.category-link:active {
  color: var(--body-colour);
}
.category-link {
  .small {
    float: left;
    margin-bottom: var(--baseline-unit);
    background-color: var(--grey);
  }
  .link-title {
    float: left;
    clear: left;
    margin: 0;
    padding: 0 0 calc(var(--baseline-unit) / 2) 0;
    box-shadow: 0 calc(var(--baseline-unit) / 2) var(--sienna);
    transition: all 0.1s ease-out;
  }
  .link-intro {
    clear: both;
    padding-top: var(--baseline-unit);
  }
  .link-intro p {
    display: inline;
  }
}
a.category-link:hover,
a.category-link:focus,
a.category-link:active {
  .link-title {
    box-shadow: 0 calc(var(--baseline-unit)) var(--sienna);
  }
}

@media screen and (min-width: 700px) {
  .category-link {
    position: relative;
    min-height: 120px;
    padding-left: 1.5em;
  }
  .category-link .small {
    position: absolute;
    left: 0;
    top: 0;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    white-space: nowrap;
    line-height: 1;
    padding: var(--baseline-unit);
  }
}

/* Modifier for a pictoral version */
@media screen and (min-width: 700px) {
  .category-link.-pictoral {
    position: relative;
    min-height: 180px;
    padding-left: 156px;

    .roundel {
      position: absolute;
      left: 0;
      z-index: -1;
    }
    .small {
      top: 10px;
    }
    .link-title {
      margin-top: 10px;
    }
  }
}

/*----------------------------- PAGING -----------------------------*/

.paging {
  border-top: 1px dashed var(--paynes-grey-tint-2);
  padding-bottom: calc(var(--baseline-unit) * 4);
}
@media screen and (min-width: 468px) {
  .paging > nav {
    display: grid;
    gap: calc(var(--baseline-unit) * 8);
    grid-template-columns: 1fr 1fr;
  }
}

.paging-prefix {
  margin-bottom: calc(var(--baseline-unit) * 4);
}

@media screen and (min-width: 468px) {
  .paging-prev {
    grid-column-start: 1;
    grid-row-start: 1;
  }
  .paging-next {
    grid-column-start: 2;
    grid-row-start: 1;
    text-align: right;

    .category-link {
      text-align: right;
      padding-left: 0;
      padding-right: 1.5em;

      .small {
        right: 0;
        left: auto;
      }
      .link-title {
        float: right;
      }
    }
  }
}

/*----------------------------- PICTORAL EXHIBITS -----------------------------*/

.exhibit {
  padding-top: calc(var(--baseline-unit) * 6);
}
@media screen and (min-width: 468px) {
  .exhibit {
    padding-top: calc(var(--baseline-unit) * 8);
  }
}

.pictoral-image {
  margin: 0 auto;
}

/*----------------------------- Utility -----------------------------*/

.small {
  font-size: var(--small-font-size);
}

.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

/*--------------------------------------------------------------------*/
/*----------------------------- Timeline and talks -----------------------------*/

.timeline-unit {
  margin-bottom: calc(var(--baseline-unit) * 10);
}
@media screen and (min-width: 700px) {
  .timeline-unit {
    position: relative;
    padding-left: 2.5em;
    margin-bottom: calc(var(--baseline-unit) * 20);
  }
}

.timeline-date {
  //border-top: 1px solid var(--paynes-grey);
  color: var(--white);
  background-color: var(--body-colour);
}
@media screen and (min-width: 700px) {
  .timeline-date {
    border-top: none;
    position: absolute;
    left: 0;
    top: 0;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    white-space: nowrap;
    line-height: 1;
    padding: var(--baseline-unit);
  }
}

.timeline-position {
  .--position {
    margin-bottom: 0;
  }
}

.timeline-conf-talk {
  border: 1px dashed var(--paynes-grey-tint-3);
  padding: calc(var(--baseline-unit) * 4);
  padding-top: 0;
}

.timeline-significant-event {
  border: 1px solid var(--warm-yellow);
}

.timeline-image {
  .--mission-patch img {
    max-width: 240px;
  }
}

/* ==== PANEL TEST ==== */
@media screen and (min-width: 700px) {
  .grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: calc(var(--baseline-unit) * 6);
    align-items: start;
  }
}
@media screen and (min-width: 800px) {
  .grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: calc(var(--baseline-unit) * 6);
    align-items: start;
  }
}
@media screen and (min-width: 800px) {
  .grid-2-3 {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: calc(var(--baseline-unit) * 6);
    align-items: start;
  }
}


.panel-unit {
  position: relative;
  min-height: 120px;
  margin-bottom: calc(var(--baseline-unit) * 8);

  a {
    display: block;
    box-shadow: none;
    padding-right: 1.263157894736842em;
    transition: all 0.1s ease-out;
  
    &:hover,
    &:focus,
    &:active {
      .heading-m {
        box-shadow: 0 4px var(--sienna);
      }
    }
  }

  .interior {
    padding-bottom: calc(var(--baseline-unit) * 4);
    transition: all 0.1s ease-out;
    border-bottom: 1px dashed var(--grey);
  }
  .interior.with-thumb {
    position: relative;
    padding-left: 140px;
    min-height: 132px;
  }

  .thumb {
    position: absolute;
    left: 0;
    top: 0;
    width: 124px;
    aspect-ratio: 1 / 1;
    background-position: center;
    background-size: cover;
  }

  .date {
    font-size: var(--small-font-size);
    white-space: nowrap;
    line-height: 1;
  }

  .heading-m {
    display: inline-block;
    margin-top: calc(var(--baseline-unit) * 2);
    padding-bottom: calc(var(--baseline-unit) / 2);
    box-shadow: 0 calc(var(--baseline-unit) / 2) var(--sienna);
    transition: all 0.1s ease-out;
  }

  p {
    display: inline;
  }
}

.drafts-exp {
  display: flex;
}
